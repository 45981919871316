import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import get from 'lodash/get'
import ReactGA from 'react-ga'
import Moment from 'react-moment'
import Navigation from '../components/Navigation'
import NoNavigation from '../components/NoNavigation'
import Container from '../components/container'
import UpdateTile from '../components/UpdateTile'
import { TileGrid } from '../components/TileGrid'
import { Wrapper } from '../components/Wrapper'
import dropdownIcon from '../images/dropdown-icon.svg'
import styled from 'styled-components'
import { STYLES } from '../styles/constants'

const CategoryFilter = styled.div`
  font-size: 14px;
  color: #FFFFFF;
  text-align: left;
  line-height: 20px;
  position: relative;
  width: 162px;

  .filter-toggle {
    position: relative;
    z-index: 2;
    width: 100%;
    padding: 12px;
    cursor: pointer;
    white-space: nowrap;

    :after {
      content: '';
      background-image: url(${dropdownIcon});
      width: 24px;
      height: 24px;
      background-size: 100% 100%;
      position: absolute;
      top: 10px;
      right: 12px;
      transition: transform .3s;
    }

    &.closed {
      padding-right: 48px;
      text-align: right;
    }

    &.open {
      text-align: left;
      :after {
        transform: rotate(180deg);
      }
    }
  }

  .filter-dropdown {
    background: #333333;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    padding: 54px 0 9px 0;
    width: 100%;

    &.closed {
      opacity: 0;
      pointer-events: none;
    }

    &.open {
      opacity: 1;
      transition: opacity .3s;
    }

    :before {
      content: '';
      position: absolute;
      width: 100%;
      display: block;
      height: 1px;
      left: 0;
      top: 44px;
      background-color: rgba(34,31,31,.5);
    }
  }

  .filter-item {
    padding: 3px 12px;
    white-space: nowrap;
    margin-bottom: 6px;
    cursor: pointer;

    :last-of-type {
      margin-bottom: 0;
    }
  }
`;
const IndexHeader = styled.div`
  margin-top: 48px;
  text-align: center;
  padding: 0 ${STYLES.PADDING_MOBILE};

  .year {
    font-family: "Montserrat";
    font-weight: ${STYLES.FW_SEMIBOLD};
    font-size: 12px;
    color: ${STYLES.COLOR_PN_YELLOW_DARK};
    letter-spacing: 0.5px;
    line-height: 20px;
    margin-bottom: 8px;
    text-transform: uppercase;
  }

  h1 {
    max-width: 640px;
    margin: 0 auto 16px auto;
    font-family: "Montserrat";
    font-weight: ${STYLES.FW_BOLD};
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 48px;
  }

  p {
    max-width: 640px;
    margin: 0 auto 16px auto;
    font-family: "Noto Sans";
    font-size: 16px;
    line-height: 28px;
  }

  .arrow {
    margin-bottom: 48px;

    svg {
      width: 16px;
      height: 17px;
    }
  }

  @media (min-width: ${STYLES.BP_MEDIUM_MIN}) {
    margin-top: 72px;

    h1 {
      margin-bottom: 72px;
    }

    .arrow {
      margin-bottom: 72px;
    }
  }
`;

const removeDuplicates = (myArr, prop) => {
  return myArr.filter((obj, pos, arr) => {
    return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos
  })
}

class CollectionTemplate extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      updates: get(this, 'props.data.contentfulCollection').updates,
      filterDropdown: 'closed',
      filter: {}
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false)
  }

  goBack = () => {
    ReactGA.event({
      category: 'Update',
      action: 'Click back arrow',
      label: 'from: ' + this.props.pageContext.slug
    })
    window.history.back()
  }

  getTagsFromUpdates = () => {
    let tags = [];
    this.state.updates.forEach(update => {
      update.tags.forEach(tag => tags.push(tag))
    })

    return removeDuplicates(tags, 'id')
  }

  filter = (tag) => {
    this.setState({ filter: tag })
    this.toggleFilterDropdown()
    ReactGA.event({
      category: 'Filter',
      action: 'Filter updates',
      label: tag.name
    })
  }

  clearFilter = () => {
    this.setState({ filter: {} })
    this.closeFilterDropdown()
    ReactGA.event({
      category: 'Filter',
      action: 'Filter updates',
      label: 'All'
    })
  }

  hasActiveFilter = () => {
    return this.state.filter.hasOwnProperty('id')
  }

  toggleFilterDropdown = () => {
    const newState = this.state.filterDropdown === 'closed' ? 'open' : 'closed'
    this.setState({ filterDropdown: newState })

    if (this.state.filterDropdown === 'open') {
      document.addEventListener('mousedown', this.handleClick, false)
    }
  }

  closeFilterDropdown = () => {
    this.setState({ filterDropdown: 'closed' })
  }

  handleClick = (e) => {
    if ((this.dropdownRef && this.dropdownRef.contains(e.target)) ||
      (this.toggleRef && this.toggleRef.contains(e.target))) {
      return
    }

    this.closeFilterDropdown()
  }

  getFilteredUpdates = () => {
    if (!this.state.filter.hasOwnProperty('id')) return this.state.updates

    return this.state.updates.filter(update => {
      let match = false
      update.tags.forEach(tag => {
        if (tag.id === this.state.filter.id) match = true
      })
      return match
    })
  }

  render() {
    const collection = get(this, 'props.data.contentfulCollection')
    const siteTitle = get(this.props, 'data.site.siteMetadata.title')
    const backArrowSVG =
      `<defs>
        <polygon id="shape-a" points="16 7 3.83 7 9.42 1.41 8 0 0 8 8 16 9.41 14.59 3.83 9 16 9"/>
      </defs>
      <use fill="#FFF" xlink:href="#shape-a"/>`

    return (
      <Container>
        <Helmet title={`${collection.title} | ${siteTitle}`} bodyAttributes={{
              class: collection.lightMode ? `light` : 'dark'
          }}>
          <meta name="robots" content="noindex, nofollow" />
        </Helmet>
        {collection.hideNav && 
        <NoNavigation />
        }
        {!collection.hideNav && 
        <Navigation />
        }
        <Wrapper containerPadding>
            <IndexHeader>
              {collection.showDate &&
                <>
                <div className="year"><Moment format="MMMM YYYY">{collection.date}</Moment></div>
                </>
              }
              {!collection.showDate &&
                <>
                <div className="year">{collection.title}</div>
                </>
              }
              <h1>{collection.description.description}</h1>
              {/* <p>{currentCollection.description.description}</p> */}
              {/* <div className="arrow">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17">
                  <g fill="none" fillRule="evenodd" transform="translate(-4 -3)">
                    <rect width="24" height="24" />
                    <polygon fill="#FBB415" fillRule="nonzero" points="20 12.357 18.59 11.01 12 17.306 5.42 11 4 12.357 12 20" />
                    <polygon fill="#FBB415" fillRule="nonzero" points="13 14 13 3 11 3 11 14 12 15" />
                  </g>
                </svg>
              </div> */}
            </IndexHeader>
          <TileGrid className={this.getFilteredUpdates().length % 3 === 0 || this.getFilteredUpdates().length % 5 === 0 ? "threeup" : "twoup"}>
            {this.getFilteredUpdates().map(update => (
              <UpdateTile update={update} key={update.slug} />
            ))}
          </TileGrid>
        </Wrapper>
      </Container>
    )
  }
}

export default CollectionTemplate

export const pageQuery = graphql`
  query CollectionBySlug($slug: String!) {
    #SITE METADATA
    site {
      siteMetadata {
        title
      }
    }
    
    contentfulCollection(slug: { eq: $slug }) {
      id
      title
      date
      description {
        description
      }
      updates {
        title
        slug
        tags {
          name
          id
        }
        thumbnail {
          sizes(maxWidth: 704, maxHeight: 704) {
            ...GatsbyContentfulSizes
          }
        }
      }
      live
      showDate
      lightMode
      hideNav
    }
  }
`
